import { lazy } from "react";

// project imports
import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";
import ViewStudent from "views/pages/batch/ViewStudent";
import UpdateQuestion from "views/pages/entranceexam/UpdateQuestion";
import UpdateRegQuestion from "views/pages/regularexam/UpdateQuestion";

import SendNotification from "../views/pages/MassCommunication/SendNotification";
import AllNotifications from "../views/pages/MassCommunication/AllNotifications";
// dashboard routing
const DashboardDefault = Loadable(
  lazy(() => import("views/dashboard/Default"))
);
const CreateExam = Loadable(
  lazy(() => import("views/pages/entranceexam/CreateExam"))
);
const AllGrievanceRequest = Loadable(
  lazy(() => import("views/pages/AdminGrievance/AllGrievanceRequestAdmin"))
);

const CreateRegularExam = Loadable(
  lazy(() => import("views/pages/regularexam/CreateExam"))
);
const AllExams = Loadable(
  lazy(() => import("views/pages/entranceexam/AllExams"))
);
const AllRegularExams = Loadable(
  lazy(() => import("views/pages/regularexam/AllExams"))
);
const AllocateExam = Loadable(
  lazy(() => import("views/pages/regularexam/AllocateExam"))
);
const CreateBatch = Loadable(
  lazy(() => import("views/pages/batch/createBatch"))
);
const RaiseLeave = Loadable(
  lazy(() => import("views/pages/staffLeaveManagement/ManageLeave/raiseLeave"))
);
const ManageStaffLeave = Loadable(
  lazy(() =>
    import("views/pages/staffLeaveManagement/AdminLevelManageLeave/manageLeave")
  )
);
const UpdateReadingReportStatus = Loadable(
  lazy(() => import("views/pages/readingReport/UpdateReadingReportStatus"))
);

const AllBatches = Loadable(lazy(() => import("views/pages/batch/allBatches")));
const SetQuestionsForExam = Loadable(
  lazy(() => import("views/pages/entranceexam/SetQuestionsForExam"))
);
const SetQuestionsForRegularExam = Loadable(
  lazy(() => import("views/pages/regularexam/SetQuestionsForExam"))
);
const ShowQuestions = Loadable(
  lazy(() => import("views/pages/entranceexam/ShowQuestions"))
);
const ShowQuestionsForRegular = Loadable(
  lazy(() => import("views/pages/regularexam/ShowQuestions"))
);
const CreateQuestion = Loadable(
  lazy(() => import("views/pages/questionBank/CreateQuestion"))
);
const AllQuestions = Loadable(
  lazy(() => import("views/pages/questionBank/AllQuestions"))
);
const EditQuestion = Loadable(
  lazy(() => import("views/pages/questionBank/EditQuestion"))
);
const GenerateMerit = Loadable(
  lazy(() => import("views/pages/batch/GenerateMerit"))
);
const GenerateICard = Loadable(
  lazy(() => import("views/pages/batch/GenerateICard"))
);
const UploadResult = Loadable(
  lazy(() => import("views/pages/entranceexam/UploadResult"))
);
const UploadRegularResult = Loadable(
  lazy(() => import("views/pages/regularexam/UploadResult"))
);
const GenerateQuestionPaperRegular = Loadable(
  lazy(() => import("views/pages/regularexam/GenerateQuestionPaper"))
);
const GenerateQuestionPaper = Loadable(
  lazy(() => import("views/pages/entranceexam/GenerateQuestionPaper"))
);
const CreateLecture = Loadable(
  lazy(() => import("views/pages/LectureSchedule/CreateLecture"))
);
const ShowLectures = Loadable(
  lazy(() => import("views/pages/LectureSchedule/ShowLectures"))
);
const Student = Loadable(
  lazy(() => import("views/pages/VerifyStudent/Student"))
);
const Profile = Loadable(
  lazy(() => import("views/pages/VerifyStudent/Profile"))
);
const ManageLeave = Loadable(
  lazy(() => import("views/pages/leaveManagement/ManageLeave/manageLeave"))
);
const LeaveReport = Loadable(
  lazy(() => import("views/pages/leaveManagement/LeaveReport/leaveReport"))
);
const AddBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/AddBook"))
);
const LibraryRules = Loadable(
  lazy(() => import("views/pages/libraryManagement/LibraryRules"))
);
const AllBooks = Loadable(
  lazy(() => import("views/pages/libraryManagement/AllBooks"))
);
const DisposeBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/DisposeBook"))
);
const IssueBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/IssueBook"))
);
const RenewBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/RenewBook"))
);
const ReceiveBook = Loadable(
  lazy(() => import("views/pages/libraryManagement/ReceiveBook"))
);
const UnVerifiedStudents = Loadable(
  lazy(() => import("views/pages/VerifyStudent/UnVerifiedStudent"))
);
const VerifiedStudent = Loadable(
  lazy(() => import("views/pages/VerifyStudent/VerifiedStudent"))
);
const CancelWaiting = Loadable(
  lazy(() => import("views/pages/libraryManagement/CancelWaiting"))
);
const SubmitAttendance = Loadable(
  lazy(() => import("views/pages/attendance/submitAttendence"))
);
const LectureAttendance = Loadable(
  lazy(() => import("views/pages/LectureSchedule/LectureAttendance"))
);
const AttendanceReport = Loadable(
  lazy(() => import("views/pages/attendance/AttendenceReport/AttendenceReport"))
);

const MyRaisedGrivance = Loadable(
  lazy(() => import("views/pages/AdminGrievance/MyRaisedGrivance"))
);
const RaiseGrivance = Loadable(
  lazy(() => import("views/pages/AdminGrievance/RaiseGrivance"))
);

const EntranceExamResult = Loadable(
  lazy(() => import("views/pages/entranceexam/ViewResult"))
);
const RegularExamResult = Loadable(
  lazy(() => import("views/pages/regularexam/ViewResult"))
);
const StudentProfile = Loadable(
  lazy(() => import("views/pages/studentProfile/Profile"))
);
const CreateDisciplinaryAction = Loadable(
  lazy(() => import("views/pages/DisciplinaryAction/CreateDisciplinaryAction"))
);
const AllDisciplinaryActions = Loadable(
  lazy(() => import("views/pages/DisciplinaryAction/AllDisciplinaryActions"))
);
const DisciplinaryReport = Loadable(
  lazy(() => import("views/pages/DisciplinaryAction/DisciplinaryReport"))
);

const StaffLeaveCounter = Loadable(
  lazy(() => import("views/pages/adminTools/StaffLeaveCounter"))
);

const CancelAdmissionRequest = Loadable(
  lazy(() => import("views/pages/AdminCancelAdmission/CreateCancelAdmission"))
);
const AllCancelAdmissionRequest = Loadable(
  lazy(() =>
    import("views/pages/AdminCancelAdmission/AllCancelAdmissionRequest")
  )
);
const SearchStudentFile = Loadable(
  lazy(() => import("views/pages/StudentFile/searchStudentFile"))
);

const AllMainExams = Loadable(
  lazy(() => import("views/pages/mainExam/AllMainExams"))
);
const SetQuestionsForMainExam = Loadable(
  lazy(() => import("views/pages/mainExam/SetQuestionsForMainExam"))
);
const ShowQuestionsForMain = Loadable(
  lazy(() => import("views/pages/mainExam/ShowQuestionsForMain"))
);
const GenerateQuestionPaperMain = Loadable(
  lazy(() => import("views/pages/mainExam/GenerateQuestionPaperMain"))
);

const UpdateMainQuestion = Loadable(
  lazy(() => import("views/pages/mainExam/UpdateMainQuestion"))
);
const MainExamResult = Loadable(
  lazy(() => import("views/pages/mainExam/MainExamResult"))
);
const ViewResult = Loadable(
  lazy(() => import("views/pages/mainExam/ViewResult"))
);
const ShowStats = Loadable(
  lazy(() => import("views/pages/mainExam/ShowStats"))
);

const GetUsers = Loadable(
  lazy(() => import("views/pages/adminTools/GetUsers"))
);
const ChangePassword = Loadable(
  lazy(() => import("views/pages/ChangePassword/Password.js"))
);

const CreateMainExam = Loadable(
  lazy(() => import("views/pages/mainExam/CreateMainExam"))
);
const MainsCreateQuestion = Loadable(
  lazy(() => import("views/pages/mainsquestionBank/CreateQuestion"))
);
const MainsAllQuestions = Loadable(
  lazy(() => import("views/pages/mainsquestionBank/AllQuestions"))
);
const MainsEditQuestion = Loadable(
  lazy(() => import("views/pages/mainsquestionBank/EditQuestion"))
);
const AllReadingUpdates = Loadable(
  lazy(() => import("views/userPage/ReadingReport/AllReadingUpdates"))
);
const EnterDailyUpdate = Loadable(
  lazy(() => import("views/userPage/ReadingReport/EnterDailyUpdate"))
);
const MyDisciplinaryAction = Loadable(
  lazy(() => import("views/pages/DisciplinaryAction/MyDisciplinaryAction"))
);
const MyPerformence = Loadable(
  lazy(() => import("views/userPage/StudentPerformence/MyPerformence"))
);
const CreateNewUser = Loadable(
  lazy(() => import("views/pages/adminTools/CreateNewUser"))
);
const CreateLibraryRules = Loadable(
  lazy(() => import("views/pages/adminTools/LibraryRules"))
);
const CreateRooms = Loadable(
  lazy(() => import("views/pages/adminTools/CreateRooms"))
);

const CreateTopics = Loadable(
  lazy(() => import("views/pages/adminTools/CreateTopics"))
);
const CreateSubjects = Loadable(
  lazy(() => import("views/pages/adminTools/CreateSubjects"))
);
const CreateTargetedExam = Loadable(
  lazy(() => import("views/pages/adminTools/CreateTargetedExams"))
);
const EnterAgrinisData = Loadable(
  lazy(() => import("views/pages/AgrinisData/EnterAgrinisData"))
);
const GetAllAgrinisData = Loadable(
  lazy(() => import("views/pages/AgrinisData/GetAllAgrnis"))
);

const CommunicateAgrinis = Loadable(
  lazy(() => import("views/pages/AgrinisData/CommunicateAgrinis"))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/dashboard",
  element: <MainLayout />,
  children: [
    {
      path: "/dashboard",
      element: <DashboardDefault />,
    },
    {
      path: "studentProfile/:studentIdParams",
      element: <StudentProfile />,
    },

    {
      path: "entranceExam",
      children: [
        {
          path: "createExam",
          element: <CreateExam />,
        },
        {
          path: "updateExam/:examIdParams",
          element: <CreateExam />,
        },
        {
          path: "allExams",
          element: <AllExams />,
        },
        {
          path: "setQuestions",
          element: <SetQuestionsForExam />,
        },
        {
          path: "setQuestions/:examIdParams/:examNameParams",
          element: <SetQuestionsForExam />,
        },
        {
          path: "showQuestions/:examIdParams/:examNameParams",
          element: <ShowQuestions />,
        },
        {
          path: "generateQuestionPaper",
          element: <GenerateQuestionPaper />,
        },
        {
          path: "generateQuestionPaper",
          element: <GenerateQuestionPaper />,
        },
        {
          path: "uploadResult",
          element: <UploadResult />,
        },
        {
          path: "uploadResult/:examIdParams",
          element: <UploadResult />,
        },
        {
          path: "ViewResult/:examIdParams",
          element: <EntranceExamResult />,
        },
        {
          path: "EditQuestion/:examIdParams/:questionIdParams",
          element: <UpdateQuestion />,
        },
      ],
    },
    {
      path: "cancelAdmission",
      children: [
        {
          path: "cancelAdmissionRequest",
          element: <CancelAdmissionRequest />,
        },
        {
          path: "allCancelAdmissionRequest",
          element: <AllCancelAdmissionRequest />,
        },
      ],
    },
    {
      path: "agrinisData",
      children: [
        {
          path: "enterAgrinisData",
          element: <EnterAgrinisData />,
        },
        {
          path: "getAllAgrinisData",
          element: <GetAllAgrinisData />,
        },
        {
          path: "communicateAgrinis",
          element: <CommunicateAgrinis />,
        },
      ],
    },
    {
      path: "MassCommunication",
      children: [
        {
          path: "SendNotification",
          element: <SendNotification />,
        },
        {
          path: "AllNotifications",
          element: <AllNotifications />,
        },
      ],
    },
    {
      path: "mainExam",
      children: [
        {
          path: "createExam",
          element: <CreateMainExam />,
        },
        {
          path: "createExam/:examIdParams",
          element: <CreateMainExam />,
        },
        {
          path: "allExams",
          element: <AllMainExams />,
        },
        {
          path: "setQuestions",
          element: <SetQuestionsForMainExam />,
        },
        {
          path: "setQuestions/:examIdParams/:examNameParams",
          element: <SetQuestionsForMainExam />,
        },
        {
          path: "showQuestions/:examIdParams/:examNameParams/:batchIdParams",
          element: <ShowQuestionsForMain />,
        },
        {
          path: "ViewStats/:examIdParams",
          element: <ShowStats />,
        },
        {
          path: "ViewResult/:examIdParams",
          element: <ViewResult />,
        },
        {
          path: "generateQuestionPaper",
          element: <GenerateQuestionPaperMain />,
        },
        {
          path: "ViewResult/:examIdParams",
          element: <MainExamResult />,
        },
        {
          path: "EditQuestion/:examIdParams/:batchIdParams/:questionIdParams",
          element: <UpdateMainQuestion />,
        },
      ],
    },
    {
      path: "regularExam",
      children: [
        {
          path: "allocateExam",
          element: <AllocateExam />,
        },

        {
          path: "createExam",
          element: <CreateRegularExam />,
        },
        {
          path: "updateExam/:examIdParams",
          element: <CreateRegularExam />,
        },
        {
          path: "allExams",
          element: <AllRegularExams />,
        },
        {
          path: "generateResult",
          element: <UploadRegularResult />,
        },
        {
          path: "setQuestions",
          element: <SetQuestionsForRegularExam />,
        },
        {
          path: "setQuestions/:examIdParams/:examNameParams",
          element: <SetQuestionsForRegularExam />,
        },
        {
          path: "showQuestions/:examIdParams/:examNameParams/:batchIdParams",
          element: <ShowQuestionsForRegular />,
        },
        {
          path: "generateQuestionPaper",
          element: <GenerateQuestionPaperRegular />,
        },
        {
          path: "uploadResult",
          element: <UploadRegularResult />,
        },
        {
          path: "uploadResult/:examIdParams",
          element: <UploadRegularResult />,
        },
        {
          path: "ViewResult/:examIdParams",
          element: <RegularExamResult />,
        },
        {
          path: "EditQuestion/:examIdParams/:batchIdParams/:questionIdParams",
          element: <UpdateRegQuestion />,
        },
      ],
    },

    {
      path: "lectureArrangement",
      children: [
        {
          path: "scheduleLecture",
          element: <CreateLecture />,
        },
        {
          path: "allLectures",
          element: <ShowLectures />,
        },
        {
          path: "lectureAttendance/:lectureId",
          element: <LectureAttendance />,
        },
        {
          path: "updateLecture/:lectureId",
          element: <CreateLecture />,
        },
      ],
    },
    {
      path: "disciplinaryAction",
      children: [
        {
          path: "createDisciplinaryAction",
          element: <CreateDisciplinaryAction />,
        },
        {
          path: "allDisciplinaryAction",
          element: <AllDisciplinaryActions />,
        },
        {
          path: "disciplinaryReport",
          element: <DisciplinaryReport />,
        },
        {
          path: "myDisciplinaryActions",
          element: <MyDisciplinaryAction />,
        },
      ],
    },
    {
      path: "verifyStudent",
      children: [
        {
          path: "unverifiedStudents",
          element: <UnVerifiedStudents />,
        },
        {
          path: "verifiedStudents",
          element: <VerifiedStudent />,
        },
        {
          path: "profile/:studentIdParams",
          element: <Profile />,
        },
        {
          path: "unverifiedStudents/:batchCodeParams",
          element: <UnVerifiedStudents />,
        },
        {
          path: "verifiedStudents/:batchCodeParams",
          element: <VerifiedStudent />,
        },
        {
          path: "StudentProfile/:studentIdParams/:batchCodeParams",
          element: <Student />,
        },
      ],
    },
    {
      path: "batch",
      children: [
        {
          path: "updateDetails/:batchCodeParams",
          element: <CreateBatch />,
        },
        {
          path: "createBatch",
          element: <CreateBatch />,
        },
        {
          path: "allBatches",
          element: <AllBatches />,
        },

        {
          path: "generateMerit",
          element: <GenerateMerit />,
        },
        {
          path: "generateICard",
          element: <GenerateICard />,
        },
        {
          path: "generateMerit/:batchCodeParams",
          element: <GenerateMerit />,
        },
        {
          path: "ViewStudent/:batchCodeParams",
          element: <ViewStudent />,
        },
      ],
    },
    {
      path: "questionBank",
      children: [
        {
          path: "createQuestion",
          element: <CreateQuestion />,
        },
        {
          path: "allQuestions",
          element: <AllQuestions />,
        },
        {
          path: "editQuestion/:questionIdParams",
          element: <EditQuestion />,
        },
      ],
    },
    {
      path: "mainsquestionBank",
      children: [
        {
          path: "createQuestion",
          element: <MainsCreateQuestion />,
        },
        {
          path: "allQuestions",
          element: <MainsAllQuestions />,
        },
        {
          path: "editQuestion/:questionIdParams",
          element: <MainsEditQuestion />,
        },
      ],
    },
    {
      path: "grievanceManagement",
      children: [
        {
          path: "raiseGrivance",
          element: <RaiseGrivance />,
        },
        {
          path: "myRaisedGrivance",
          element: <MyRaisedGrivance />,
        },
        {
          path: "allGrievanceRequest",
          element: <AllGrievanceRequest />,
        },
      ],
    },
    {
      path: "lectureArrangement",
      children: [
        {
          path: "scheduleLecture",
          element: <CreateLecture />,
        },
        {
          path: "allLectures",
          element: <ShowLectures />,
        },
        {
          path: "lectureAttendance/:lectureId",
          element: <LectureAttendance />,
        },
        {
          path: "updateLecture/:lectureId",
          element: <CreateLecture />,
        },
      ],
    },

    {
      path: "readingReport",
      children: [
        {
          path: "enterDailyUpdate",
          element: <EnterDailyUpdate />,
        },
        {
          path: "allReadingUpdates",
          element: <AllReadingUpdates />,
        },
        {
          path: "upadateReadingStatus",
          element: <UpdateReadingReportStatus />,
        },
      ],
    },
    {
      path: "leaveManagement",
      children: [
        {
          path: "manageLeave",
          element: <ManageLeave />,
        },
        {
          path: "leaveReport",
          element: <LeaveReport />,
        },
        {
          path: "leaveCounter",
          element: <StaffLeaveCounter />,
        },
      ],
    },
    {
      path: "staffLeaveManagement",
      children: [
        {
          path: "raiseLeave",
          element: <RaiseLeave />,
        },
        {
          path: "myLeaves",
          element: <ManageStaffLeave />,
        },
        {
          path: "manageLeaveRequest",
          element: <ManageStaffLeave />,
        },
      ],
    },
    {
      path: "studentFile",
      children: [
        {
          path: "searchStudentFile",
          element: <SearchStudentFile />,
        },
        {
          path: "searchStudentFile/:StudentId",
          element: <SearchStudentFile />,
        },
      ],
    },
    {
      path: "libraryManagement",
      children: [
        {
          path: "addBook",
          element: <AddBook />,
        },
        {
          path: "libraryRules",
          element: <LibraryRules />,
        },
        {
          path: "allBooks",
          element: <AllBooks />,
        },
        {
          path: "disposeBook",
          element: <DisposeBook />,
        },
        {
          path: "issueBook",
          element: <IssueBook />,
        },
        {
          path: "renewBook",
          element: <RenewBook />,
        },
        {
          path: "receiveBook",
          element: <ReceiveBook />,
        },
        {
          path: "cancelWaiting",
          element: <CancelWaiting />,
        },
      ],
    },
    {
      path: "Attendance",
      children: [
        {
          path: "submitAttendance",
          element: <SubmitAttendance />,
        },
        {
          path: "attendanceReport",
          element: <AttendanceReport />,
        },
      ],
    },
    {
      path: "studentPerformence",
      children: [
        {
          path: "myPerformence",
          element: <MyPerformence />,
        },
      ],
    },
    {
      path: "admintools",
      children: [
        {
          path: "createUser",
          element: <CreateNewUser />,
        },
        {
          path: "changePassword",
          element: <ChangePassword />,
        },
        {
          path: "createUser/:userId",
          element: <CreateNewUser />,
        },
        {
          path: "createLibraryRules",
          element: <CreateLibraryRules />,
        },
        {
          path: "createRooms",
          element: <CreateRooms />,
        },
        {
          path: "createTopics",
          element: <CreateTopics />,
        },
        {
          path: "createSubjects",
          element: <CreateSubjects />,
        },
        {
          path: "createTargetedExam",
          element: <CreateTargetedExam />,
        },
        {
          path: "users",
          element: <GetUsers />,
        },
      ],
    },
  ],
};

export default MainRoutes;
