import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  TextField,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Select,
  Typography,
  Grid,
  Checkbox,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { toast } from "react-toastify";
import endPoints from "../../../routes/NetworkRoutes";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import UploadToAWS from "../../utilities/AWSUpload";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const MassCommunicationForm = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Loader state
  const [formData, setFormData] = useState({
    type: "",
    message: "",
    attachments: [],
    sendVia: "",
    studentId: [],
    batches: [],
    title: "",
  });

  const userData = JSON.parse(
    localStorage.getItem("User State")
      ? localStorage.getItem("User State")
      : "{}"
  );
  const [batchOptions, setBatchOptions] = useState([]);

  const [studentdata, setStudentdata] = useState([]);
  const [studentQuery, setStudentQuery] = useState([]);

  useEffect(() => {
    if (formData.type === "Batches") {
      const fetchData = async () => {
        try {
          const response = await axios.get(endPoints.GetAllActiveBatches, {
            headers: {
              Authorization: `Bearer ${userData.token}`,
            },
          });
          const data = response.data;
          setBatchOptions(data.data);
          console.log(data);
        } catch (error) {
          console.error(error);
        }
      };

      fetchData();
    }
  }, [formData.type]);

  const getSuggestedList = async (e) => {
    const value = e.target.value;
    if (value === "") return;

    setLoading(true); // Set loading to true before API call
    try {
      const response = await axios.get(endPoints.getStudentSearchList + value, {
        headers: {
          Authorization: `Bearer ${userData.token}`,
        },
      });
      const suggestions = response.data.data.map((suggestion) => ({
        sid: suggestion.SID,
        value:
          suggestion.SID +
          " - " +
          suggestion.FirstName +
          " " +
          suggestion.LastName,
      }));
      setStudentdata(suggestions);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading to false after API call
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleMultiSelectChange = (e, name) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: typeof value === "string" ? value.split(",") : value,
    }));
  };

  const handleError = (error) => {
    console.log(error);
    if (error.code === "ERR_BAD_REQUEST") {
      toast.error(error.response.data.data[0].msg);
    } else if (error.code === "ERR_BAD_RESPONSE") {
      if (error.response.data.message) {
        toast.error(error.response.data.message);
      }
      toast.error("Error in uploading evaluation");

      if (error.response.data.message === "jwt expired") {
        window.localStorage.clear();
        navigate("/login");
      }
    } else {
      if (error?.message) {
        toast.error(error.message);
      } else {
        toast.error("Error in Submitting Evaluation");
      }
    }
  };

  const handleFileUpload = (e) => {
    const files = Array.from(e.target.files);

    // Convert files to Data URI format
    const convertToDataURI = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () =>
          resolve({ name: file.name, dataURI: reader.result });
        reader.onerror = (error) => reject(error);
        reader.readAsDataURL(file);
      });
    };

    Promise.all(files.map(convertToDataURI))
      .then((dataURIs) => {
        console.log(dataURIs);
        setFormData((prev) => ({
          ...prev,
          attachments: dataURIs.concat(formData.attachments),
        }));
      })
      .catch((error) =>
        console.error("Error converting files to Data URI:", error)
      );
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      console.log("Form Data Submitted:", formData);
      console.log("Form Data Submitted:", studentQuery);
      const attaches = [];
      for (let i = 0; i < formData.attachments.length; i++) {
        attaches.push({
          file: formData.attachments[i].dataURI,
          fileName: formData.attachments[i].name,
        });
      }

      const params = {
        type: formData.type,
        message: formData.message,
        attachments: attaches,
        studentId: studentQuery,
        batches: formData.batches,
        sendVia: formData.sendVia,
        title: formData.title,
      };

      const response = await axios.post(endPoints.sendNotifications, params, {
        headers: {
          Authorization: "Bearer " + userData.token,
        },
      });
      console.log(response);
      if (response.status == 200) {
        setFormData({
          type: "",
          message: "",
          attachments: [],
          sendVia: "",
          studentId: [],
          batches: [],
          title: "",
        });
        setBatchOptions([]);
        setStudentQuery("");
      }
    } catch (err) {
      handleError(err);
    }
  };

  return (
    <Box
      className="p-4 sm:p-6 bg-gray-100 rounded-md shadow-md"
      sx={{ maxWidth: "600px", margin: "auto" }}
    >
      <ToastContainer></ToastContainer>
      <Typography variant="h5" className="mb-4 text-center font-bold">
        Mass Communication Form
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          {/* Type */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select name="type" value={formData.type} onChange={handleChange}>
                <MenuItem value="Student">Student</MenuItem>
                <MenuItem value="Batches">Batches</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Dynamic List Based on Type */}
          {formData.type === "Student" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  options={studentdata}
                  getOptionLabel={(option) => option.value}
                  onChange={(event, newValue) => {
                    if (newValue && newValue.sid) {
                      setStudentQuery((prev) => [...prev, newValue.sid]);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      onChange={getSuggestedList}
                      label="Search Student SID"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loading ? <CircularProgress size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </FormControl>
              <Typography variant="body2" className="mt-2">
                {studentQuery?.length > 0 &&
                  `Selected Students: ${studentQuery.join(", ")}`}
              </Typography>
            </Grid>
          )}

          {formData.type === "Batches" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Batches</InputLabel>
                <Select
                  name="batches"
                  multiple
                  value={formData.batches}
                  onChange={(e) => handleMultiSelectChange(e, "batches")}
                  renderValue={(selected) => selected.join(", ")}
                >
                  {batchOptions.map((batch) => (
                    <MenuItem key={batch.BatchCode} value={batch.BatchCode}>
                      <Checkbox
                        checked={formData.batches.indexOf(batch.BatchCode) > -1}
                      />
                      <ListItemText primary={batch.BatchName} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}

          <Grid item xs={12}>
            <TextField
              name="title"
              label="Title"
              fullWidth
              value={formData.title}
              onChange={handleChange}
            />
          </Grid>
          {/* Message */}
          <Grid item xs={12}>
            <TextField
              name="message"
              label="Message"
              multiline
              rows={4}
              fullWidth
              value={formData.message}
              onChange={handleChange}
              required
            />
          </Grid>

          {/* Attachments */}
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="label"
              className="w-full bg-purple-600 hover:bg-purple-700 text-white"
            >
              Upload Attachments
              <input type="file" multiple hidden onChange={handleFileUpload} />
            </Button>
            <Typography variant="body2" className="mt-2">
              {formData.attachments.length > 0 &&
                `Selected Files: ${formData.attachments
                  .map((file) => file.name)
                  .join(", ")}`}
            </Typography>
          </Grid>

          {/* Send Via */}
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel>Send Via</InputLabel>
              <Select
                name="sendVia"
                value={formData.sendVia}
                onChange={handleChange}
                required
              >
                <MenuItem value="WhatsApp">WhatsApp</MenuItem>
                <MenuItem value="Email">Email</MenuItem>
              </Select>
            </FormControl>
          </Grid>

          {/* Title */}

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className="w-full bg-purple-600 hover:bg-purple-700 text-white"
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
};

export default MassCommunicationForm;
