import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
} from "@mui/material";
import endPoints from "../../../routes/NetworkRoutes";
import axios from "axios";

const MassCommunicationTable = () => {
  // Utility function to convert Data URI to Blob and create a downloadable link
  const downloadFile = (dataUri, fileName) => {
    const byteString = atob(dataUri.split(",")[1]); // Decode base64 string
    const mimeString = dataUri.split(",")[0].split(":")[1].split(";")[0]; // Extract MIME type
    const arrayBuffer = new Uint8Array(byteString.length).map((_, i) =>
      byteString.charCodeAt(i)
    );
    const blob = new Blob([arrayBuffer], { type: mimeString });
    const url = URL.createObjectURL(blob);

    // Create a temporary link to download the file
    const link = document.createElement("a");
    link.href = url;
    link.download = fileName || "download";
    link.click();

    // Clean up
    URL.revokeObjectURL(url);
  };
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userData = JSON.parse(
          localStorage.getItem("User State")
            ? localStorage.getItem("User State")
            : "{}"
        );
        const response = await axios.get(endPoints.allNotifications, {
          headers: {
            Authorization: `Bearer ${userData.token}`,
          },
        });
        const data = response.data;
        setData(data.data);
        console.log(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="p-4">
      <TableContainer component={Paper} className="shadow-lg">
        <Table>
          {/* Table Header */}
          <TableHead>
            <TableRow className="bg-gray-100">
              <TableCell className="font-semibold text-gray-600">
                Type
              </TableCell>
              <TableCell className="font-semibold text-gray-600">
                Message
              </TableCell>
              <TableCell className="font-semibold text-gray-600">
                Attachments
              </TableCell>
              <TableCell className="font-semibold text-gray-600">
                Send Via
              </TableCell>
              <TableCell className="font-semibold text-gray-600">
                Sent On
              </TableCell>
              <TableCell className="font-semibold text-gray-600">
                Sent By
              </TableCell>
              <TableCell className="font-semibold text-gray-600">
                Student ID
              </TableCell>
              <TableCell className="font-semibold text-gray-600">
                Batches
              </TableCell>
              <TableCell className="font-semibold text-gray-600">
                Title
              </TableCell>
            </TableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={index} className="hover:bg-gray-50">
                <TableCell>{row.type}</TableCell>
                <TableCell>{row.message}</TableCell>
                <TableCell>
                  {row.attachments?.length > 0 ? (
                    <ul className="list-disc pl-4">
                      {row.attachments.map((attachment, idx) => (
                        <li key={idx} className="flex items-center space-x-2">
                          <span>{attachment.fileName}</span>
                          <Button
                            variant="outlined"
                            size="small"
                            onClick={() =>
                              downloadFile(attachment.file, attachment.fileName)
                            }
                          >
                            Download
                          </Button>
                        </li>
                      ))}
                    </ul>
                  ) : (
                    "N/A"
                  )}
                </TableCell>
                <TableCell>{row.sendVia}</TableCell>
                <TableCell>{new Date(row.sentOn).toLocaleString()}</TableCell>
                <TableCell>{row.sentBy}</TableCell>
                <TableCell>{row.studentId || "N/A"}</TableCell>
                <TableCell>
                  {row.batches?.length > 0 ? row.batches.join(", ") : "N/A"}
                </TableCell>
                <TableCell>{row.title || "N/A"}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default MassCommunicationTable;
