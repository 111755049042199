// assets
import { IconDashboard } from "@tabler/icons";

import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ArticleIcon from "@mui/icons-material/Article";

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const dashboard = {
  id: "dashboard",
  // title: 'Dashboard',
  type: "group",
  children: [
    {
      id: "default",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: DashboardRoundedIcon,
      breadcrumbs: false,
    },
    {
      id: "Batch",
      title: "Batches",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        {
          id: "Create Batch",
          title: "Create Batch",
          type: "item",
          url: "/dashboard/batch/createBatch",
          breadcrumbs: false,
        },
        {
          id: "All Batches",
          title: "All Batches",
          type: "item",
          url: "/dashboard/batch/allBatches",
          breadcrumbs: false,
        },
        {
          id: "Generate Merit",
          title: "Generate Merit",
          type: "item",
          url: "/dashboard/batch/generateMerit",
          breadcrumbs: false,
        },
        {
          id: "Generate ICard",
          title: "Generate ICard",
          type: "item",
          url: "/dashboard/batch/generateICard",
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "entranceExam",
      title: "Entrance Exam",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        {
          id: "createExam",
          title: "Create Exam",
          type: "item",
          url: "/dashboard/entranceExam/createExam",
          breadcrumbs: false,
        },
        {
          id: "AllEntranceExams",
          title: "All Entrance Exams",
          type: "item",
          url: "/dashboard/entranceExam/allExams",
          breadcrumbs: false,
        },

        {
          id: "GenerateQuestionPaper",
          title: "Generate Question Paper",
          type: "item",
          url: "/dashboard/entranceExam/generateQuestionPaper",
          breadcrumbs: false,
        },
        {
          id: "Upload Result",
          title: "Upload Result",
          type: "item",
          url: "/dashboard/entranceExam/uploadResult",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "mainExam",
      title: "Mains Exam",
      type: "collapse",
      icon: ArticleIcon,
      children: [
        {
          id: "createmainExam",
          title: "Create Mains Exam",
          type: "item",
          url: "/dashboard/mainExam/createExam",
          breadcrumbs: false,
        },
        {
          id: "All Mains Exams",
          title: "All Mains Exams",
          type: "item",
          url: "/dashboard/mainExam/allExams",
          breadcrumbs: false,
        },
        {
          id: "GenerateQuestionPaper",
          title: "Generate Question Paper",
          type: "item",
          url: "/dashboard/mainExam/generateQuestionPaper",
          breadcrumbs: false,
        },
      ],
    },

    {
      id: "regularExam",
      title: "Regular Exam",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        /*    {
                    id: 'allocateExam',
                    title: 'Allocate Exam',
                    type: 'item',
                    url: '/dashboard/regularExam/allocateExam',
                    breadcrumbs: false
                }, */
        {
          id: "createExam",
          title: "Create Exam",
          type: "item",
          url: "/dashboard/regularExam/createExam",
          breadcrumbs: false,
        },
        {
          id: "All Regular Exams",
          title: "All Regular Exams",
          type: "item",
          url: "/dashboard/regularExam/allExams",
          breadcrumbs: false,
        },
        {
          id: "Upload Result",
          title: "Upload Result",
          type: "item",
          url: "/dashboard/regularExam/generateResult",
          breadcrumbs: false,
        },
        {
          id: "GenerateQuestionPaper",
          title: "Generate Question Paper",
          type: "item",
          url: "/dashboard/regularExam/generateQuestionPaper",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "VerifyStudent",
      title: "Verify Student",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        {
          id: "Unverified Students",
          title: "Unverified Students",
          type: "item",
          url: "/dashboard/verifyStudent/unverifiedStudents",
          breadcrumbs: false,
        },
        {
          id: "Verified Students",
          title: "Verified Students",
          type: "item",
          url: "/dashboard/verifyStudent/verifiedStudents",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "Regular Exam Questions Bank",
      title: "Regular Exam Question Bank",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        {
          id: "Create New Question",
          title: "Create New Question",
          type: "item",
          url: "/dashboard/questionBank/createQuestion",
          breadcrumbs: false,
        },
        {
          id: "AllQuestions",
          title: "All Questions",
          type: "item",
          url: "/dashboard/questionBank/allQuestions",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "Mains Exam Questions Bank",
      title: "Mains Exam Question Bank",
      type: "collapse",
      icon: ArticleIcon,
      // breadcrumbs: false
      children: [
        {
          id: "Create New Question",
          title: "Create New Question",
          type: "item",
          url: "/dashboard/mainsquestionBank/createQuestion",
          breadcrumbs: false,
        },
        {
          id: "AllQuestions",
          title: "All Questions",
          type: "item",
          url: "/dashboard/mainsquestionBank/allQuestions",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "Lecture Arrangement",
      title: "Lecture Arrangement",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "Schedule Lecture",
          title: "Schedule Lecture",
          type: "item",
          url: "/dashboard/LectureArrangement/scheduleLecture",
          breadcrumbs: true,
        },
        {
          id: "Show All Lectures",
          title: "All Scheduled Lectures",
          type: "item",
          url: "/dashboard/LectureArrangement/allLectures",
          breadcrumbs: true,
        },
      ],
    },

    {
      id: "Leave Management",
      title: "Leave Management",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "Manage Leave",
          title: "Manage Leave",
          type: "item",
          url: "/dashboard/leaveManagement/manageLeave",
          breadcrumbs: true,
        },
        {
          id: "Leave Report",
          title: "Leave Report",
          type: "item",
          url: "/dashboard/leaveManagement/leaveReport",
          breadcrumbs: true,
        },
        {
          id: "leaveCounter",
          title: "Manage Student Leave Counter",
          type: "item",
          url: "/dashboard/leaveManagement/leaveCounter",
          breadcrumbs: true,
        },
      ],
    },

    //staff leave management
    {
      id: "staffLeaveManagement",
      title: "Staff Leave Management",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "Raise Leave",
          title: "Raise Leave",
          type: "item",
          url: "/dashboard/staffLeaveManagement/raiseLeave",
          breadcrumbs: true,
        },
        {
          id: "My Leaves",
          title: "My Leaves",
          type: "item",
          url: "/dashboard/staffLeaveManagement/myLeaves",
          breadcrumbs: true,
        },
        {
          id: "manageLeaveRequest",
          title: "Manage Staff Leave Request",
          type: "item",
          url: "/dashboard/staffLeaveManagement/manageLeaveRequest",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "Library Management",
      title: "Liibrary Management",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "AddBook",
          title: "Add Book",
          type: "item",
          url: "/dashboard/libraryManagement/addBook",
          breadcrumbs: true,
        },
        {
          id: "AllBooks",
          title: "All Books",
          type: "item",
          url: "/dashboard/libraryManagement/allBooks",
          breadcrumbs: true,
        },
        {
          id: "DisposeBook",
          title: "Dispose Book",
          type: "item",
          url: "/dashboard/libraryManagement/disposeBook",
          breadcrumbs: true,
        },
        {
          id: "issueBook",
          title: "Issue Book",
          type: "item",
          url: "/dashboard/libraryManagement/issueBook",
          breadcrumbs: true,
        },
        {
          id: "renewBook",
          title: "Renew Book",
          type: "item",
          url: "/dashboard/libraryManagement/renewBook",
          breadcrumbs: true,
        },
        {
          id: "receiveBook",
          title: "Receive Book",
          type: "item",
          url: "/dashboard/libraryManagement/receiveBook",
          breadcrumbs: true,
        },
        {
          id: "cancelBookRequest",
          title: "Cancel Book Request",
          type: "item",
          url: "/dashboard/libraryManagement/cancelWaiting",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "grievanceManagement",
      title: "Grievance Management",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "raiseGrivance",
          title: "Raise Grivance",
          type: "item",
          url: "/dashboard/grievanceManagement/raiseGrivance",
          breadcrumbs: false,
        },
        {
          id: "myRaisedGrivance",
          title: "My Raised Grivance",
          type: "item",
          url: "/dashboard/grievanceManagement/myRaisedGrivance",
          breadcrumbs: false,
        },
        {
          id: "allGrievanceRequest",
          title: "All Grievance Request",
          type: "item",
          url: "/dashboard/grievanceManagement/allGrievanceRequest",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "readingReport",
      title: "Student Reading Updates",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "enterReadingDetails",
          title: "Enter Daily Update",
          type: "item",
          url: "/dashboard/readingReport/enterDailyUpdate",
          breadcrumbs: false,
        },
        {
          id: "seeAllUpdateStatus",
          title: "All Reading Updates",
          type: "item",
          url: "/dashboard/readingReport/allReadingUpdates",
          breadcrumbs: false,
        },
        {
          id: "upadateReadingStatus",
          title: "Update Reading Report",
          type: "item",
          url: "/dashboard/readingReport/upadateReadingStatus",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "disciplinaryAction",
      title: "Disciplinary Action",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "disciplinaryAction",
          title: "Create Disciplinary Action",
          type: "item",
          url: "/dashboard/disciplinaryAction/createDisciplinaryAction",
          breadcrumbs: true,
        },
        {
          id: "allDisciplinaryAction",
          title: "All Disciplinary Action",
          type: "item",
          url: "/dashboard/disciplinaryAction/allDisciplinaryAction",
          breadcrumbs: true,
        },
        {
          id: "disciplinaryReport",
          title: "Disciplinary Report",
          type: "item",
          url: "/dashboard/disciplinaryAction/disciplinaryReport",
          breadcrumbs: true,
        },
        {
          id: "MyDisciplinaryAction",
          title: "My Disciplinary Actions",
          type: "item",
          url: "/dashboard/disciplinaryAction/myDisciplinaryActions",
          breadcrumbs: true,
        },
      ],
    },

    {
      id: "MassCommunication",
      title: "Mass Communication",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "SendNotification",
          title: "Send Notification",
          type: "item",
          url: "/dashboard/MassCommunication/SendNotification",
          breadcrumbs: true,
        },
        {
          id: "AllNotifications",
          title: "All Notifications",
          type: "item",
          url: "/dashboard/MassCommunication/AllNotifications",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "cancelAdmission",
      title: "Cancel Admission",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "cancelAdmissionRequest",
          title: "Cancel Admission Request",
          type: "item",
          url: "/dashboard/cancelAdmission/cancelAdmissionRequest",
          breadcrumbs: true,
        },
        {
          id: "allCancelAdmissionRequest",
          title: "All Cancel Admission Request",
          type: "item",
          url: "/dashboard/cancelAdmission/allCancelAdmissionRequest",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "Attendance",
      title: "Attendance",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "SubmitAttendance",
          title: "Submit Attendance",
          type: "item",
          url: "/dashboard/Attendance/submitAttendance",
          breadcrumbs: true,
        },
        {
          id: "AttendanceReport",
          title: "Attendance Report",
          type: "item",
          url: "/dashboard/Attendance/attendanceReport",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "studentFile",
      title: "Student File",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "searchStudentFile",
          title: "Search Student File",
          type: "item",
          url: "/dashboard/studentFile/searchStudentFile",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "agrinisData",
      title: "Agrinis Data",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "enterAgrinisData",
          title: "Enter Agrinis Data",
          type: "item",
          url: "/dashboard/agrinisData/enterAgrinisData",
          breadcrumbs: true,
        },
        {
          id: "getAllAgrinisData",
          title: "All Agrinis Data",
          type: "item",
          url: "/dashboard/agrinisData/getAllAgrinisData",
          breadcrumbs: true,
        },
        {
          id: "communicateAgrinis",
          title: "Communicate Agrinis",
          type: "item",
          url: "/dashboard/agrinisData/communicateAgrinis",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "studentPerformence",
      title: "Student Performence",
      type: "collapse",
      url: "/dashboard/studentPerformence",
      icon: DashboardRoundedIcon,
      children: [
        {
          id: "myPerformence",
          title: "Student Performence",
          type: "item",
          url: "/dashboard/studentPerformence/myPerformence",
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "Admin Tools",
      title: "Admin Tools",
      type: "collapse",
      icon: ArticleIcon,
      breadcrumbs: true,
      children: [
        {
          id: "createUser",
          title: "Create User",
          type: "item",
          url: "/dashboard/admintools/createUser",
          breadcrumbs: true,
        },
        {
          id: "users",
          title: "All Users",
          type: "item",
          url: "/dashboard/admintools/users",
          breadcrumbs: true,
        },
        {
          id: "changePassword",
          title: "Change Password",
          type: "item",
          url: "/dashboard/admintools/changePassword",
          breadcrumbs: true,
        },

        {
          id: "createRoom",
          title: "Create Room",
          type: "item",
          url: "/dashboard/admintools/createRooms",
          breadcrumbs: true,
        },
        {
          id: "createSubjects",
          title: "Create Subjects",
          type: "item",
          url: "/dashboard/admintools/createSubjects",
          breadcrumbs: true,
        },
        {
          id: "createTopics",
          title: "Create Topics",
          type: "item",
          url: "/dashboard/admintools/createTopics",
          breadcrumbs: true,
        },
        {
          id: "createTargetedExam",
          title: "Create Targeted Exam",
          type: "item",
          url: "/dashboard/admintools/createTargetedExam",
          breadcrumbs: true,
        },
        {
          id: "createLibraryRules",
          title: "Library Rules",
          type: "item",
          url: "/dashboard/admintools/createLibraryRules",
          breadcrumbs: true,
        },
      ],
    },
  ],
};

export default dashboard;
