const mode = "production";

let domain = "";

switch (mode) {
  case "local":
    domain = "http://localhost:8000/api/";
    break;
  case "production":
    domain = "https://api.gcsafoundation.com/api/";
    break;
  case "development":
    domain = "https://gcsa-backend-1.onrender.com/api/";
    break;
  default:
    domain = "http://localhost:8001/api/";
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  getBatchDetails: (BatchCode) => {
    return `${domain}batch/getBatchOnCode/${BatchCode}`;
  },
  findUnverifiedStudents: (BatchCode) => {
    return `${domain}admission/getUnverifiedStudent/${BatchCode}`;
  },
  findverifiedStudents: (BatchCode) => {
    return `${domain}admission/getVerfiedStudent/${BatchCode}`;
  },
  getPrefinalMeritList: (BatchCode) => {
    return `${domain}exam/getPrefinalMeritList/${BatchCode}`;
  },
  uploadStudentList: `${domain}examSelectedStudent/evaluateResult`,
  addSeletedStudent: `${domain}examSelectedStudent/addToResultTable`,
  getAllSelectedStudent: `${domain}examSelectedStudent/getResultList`,
  submitDisciplinaryAction: `${domain}disciplineManagement/createAction`,
  getDisciplinaryReportsByDate: `${domain}disciplineManagement/getDisciplinaryReportByDateOrMonth`,

  getStudentFile: `${domain}studentFile/getStudentFile`,

  resolveDisciplinaryAction: `${domain}disciplineManagement/updateDisciplinaryAction`,
  getStudentPerformence: `${domain}studentPerformence/getStudentPerformence/`,
  getDisciplinaryActions: `${domain}disciplineManagement/getActionsOnType`,
  fileGrivance: `${domain}grivance/raiseGrivence`,
  getMyReadingReports: `${domain}readingreport/getMyReadingDetails`,
  getAllReadingReports: `${domain}readingreport/getAllReadingDetails`,
  updateReadingDetails: `${domain}readingreport/editReadingDetails`,
  giveReadingComments: `${domain}readingreport/provideComments`,
  addReadingDailyUpdate: `${domain}readingreport/enterTodayReadingDetails`,
  escalateGrievance: `${domain}grivance/grievances/escalate`,
  resolveGrievanceByStaff: `${domain}grivance/grievances/resolve-staff`,
  resolveGrievanceByAdmin: `${domain}grivance/grievances/resolve-admin`,
  getStaffGrivances: `${domain}grivance/grievances/Role/`,

  getStudentBatches: `${domain}cancelAdmission/getBatches`,
  requestCancelAdmission: `${domain}cancelAdmission/request`,
  getCancelRequests: `${domain}cancelAdmission/getRequests`,
  getCancelRequest: `${domain}cancelAdmission/getRequest`,
  cancelRequest: `${domain}cancelAdmission/deletePendingRequest`,
  cancelByAdmin: `${domain}cancelAdmission/cancelByAdmin`,
  enterAgrnisData: `${domain}agrinis/createAgrinis`,
  getAgrinisSearchList: `${domain}agrinis/getAgrinisSearchList/`,
  GetAllAgrinis: `${domain}agrinis/getAllAgrinis`,
  editAgrinisData: `${domain}agrinis/updateAgrinis`,
  deleteAgrinisData: `${domain}agrinis/deleteAgrinis`,
  communicateAgrinis: `${domain}agrinis/communicateAgrinis`,

  sendNotifications: `${domain}massCommunication/sendNotification`,
  allNotifications: `${domain}massCommunication/getAllNotifications`,

  getMyGrivances: `${domain}grivance/grievances/`,
  getBatchesOnType: `${domain}batch/getBatchOnType`,
  generateMerit: `${domain}exam/generateMerit`,
  generateResult: `${domain}regularexam/generateOnlineMarks`,
  uploadStudentIndividualQuestionResponse: `${domain}mainExam/studentIndividualQuestionResponseSubmit`,
  uploadStudentFinalQuestionResponse: `${domain}mainExam/submitStudentFinalResponse`,
  getExamStats: `${domain}mainExam/getExamStatsEvaluation/`,
  getExamStatsResponseCounts: `${domain}mainExam/getExamStatsResponseCounts/`,
  getExamStatsStudentDetails: `${domain}mainExam/getExamStatsStudentDetails/`,
  getStudentSubmittedResponse: `${domain}mainExam/getStudentSubmittedResponse/`,
  getTopics: `${domain}mainExam/getQuestionTopics`,
  deleteUser: `${domain}mainExam/deleteUser`,
  submittedEvaluatedResponse: `${domain}mainExam/submitEvaluatedResponse`,
  updateQuestionAllocationStatus: `${domain}mainExam/updateEvaluatorQuestionAllocation/`,
  getStudentSubmittedResponseForEvaluator: `${domain}mainExam/getStudentSubmittedResponseForEvaluator/`,
  verifyViewFormStudent: `${domain}admission/verifyViewFormStudent`,
  verifyStudent: `${domain}admission/VerifyStudent`,
  rejectAdmission: `${domain}admission/rejectStudentAdmission`,
  createExam: `${domain}exam/createExam`,
  updateExam: `${domain}exam/updateExam`,
  updateRegularExam: `${domain}regularexam/upateExamDetails/`,
  createRegularExam: `${domain}regularexam/createExam`,
  createMainExam: `${domain}mainExam/createMainExam`,
  sendOtp: `${domain}otp/sendOtp`,
  verifyOtp: `${domain}otp/verifyOtp`,
  allExams: `${domain}exam/getAllExams`,
  getAllRegularExams: `${domain}regularexam/getAllExams`,
  getAllMainsExams: `${domain}mainExam/getTypeOfMainExams/`,
  getAllRegularexam: `${domain}regularexam/getAllQuestionSetExams`,
  getregExams: `${domain}regularexam/getregExams`,
  setAllocation: `${domain}regularexam/setAllocation`,
  setQuestions: `${domain}exam/setQuestions`,
  setRegularExamQuestions: `${domain}regularexam/setQuestions`,
  setMainExamQuestions: `${domain}mainExam/setExamQuestions`,
  getQuestionsOnExamId: `${domain}exam/getMcqOnExamId`,
  getRegQuestionsOnExamId: `${domain}regularexam/getQuestionDetails/`,
  EditQuestionsOnExamId: `${domain}exam/updateExamQuestions`,
  EditQuestionsOnRegExamId: `${domain}regularexam/updateExamQuestions`,
  getQuestionsOnBatchCode: `${domain}exam/getMcqOnBatchCode`,
  getRegularExamQuestionsOnId: `${domain}regularexam/getMcqOnExamId`,
  getMainExamQuestionsOnId: `${domain}mainExam/getExamQuestions/`,
  getQustionDetailsOnId: `${domain}questionBank/getMcqDetailsOnExamId`,
  deleteMcqFromExam: `${domain}exam/editQuestions`,
  deleteMcqFromQuestionBank: `${domain}questionBank/deleteQuestion`,
  deleteMcqFromMainsQuestionBank: `${domain}mainExam/deleteQuestion`,
  getMainExamDetails: `${domain}mainExam/getMainExamDetails/`,
  getExamQuestionDetails: `${domain}mainExam/getExamQuestionDetails/`,
  updateMcqFromExam: `${domain}exam/editQuestions`,
  deleteMcqFromRegularExam: `${domain}regularexam/editQuestions`,
  updateMcqFromRegularExam: `${domain}regularexam/editQuestions`,
  examId: `${domain}exam/checkExamId`,
  RegularexamId: `${domain}regularexam/checkExamId`,
  MainexamId: `${domain}mainExam/checkExamId`,
  createBatch: `${domain}batch/createBatch`,
  getAllQuestions: `${domain}questionBank/getAllMcq`,
  getAllMainQuestions: `${domain}mainExam/getAllMainsQuestions`,
  examList: `${domain}exam/getAllExams`,
  AllBatches: `${domain}batch/getAllBatchDetails`,
  GetAllActiveBatches: `${domain}batch/getAllActiveBatchDetails`,
  GetBatchesOnDate: `${domain}batch/getBatchOnDate`,
  deleteBatch: `${domain}batch/deleteBatch`,
  createQuestion: `${domain}questionBank/createMcq`,
  createMainsQuestion: `${domain}mainExam/createNewQuestion`,
  updateMainsDetails: `${domain}mainExam/updateMainsDetails`,
  EditMainsQuestion: `${domain}mainExam/updateQuestion`,
  EditMainsExamQuestion: `${domain}mainExam/updateMainsExamQuestion`,
  editMcq: `${domain}questionBank/editMcq`,
  getBatchFromCurrentDate: `${domain}batch/getBatchFromToday`,
  getMeritListBatches: `${domain}batch/getMeritListBatches`,
  getStudentPermentData: `${domain}Admission/getPermanentData/`,
  getDashboardData: `${domain}profile/dashboardata`,
  marksEntry: `${domain}exam/marksEntry`,
  uploadDisciplinaryActionNotice: `${domain}disciplineManagement/uploadNoticeFileUrl`,
  marksEntryForRegular: `${domain}regularexam/generateResult`,
  generateRegularExamResult: `${domain}regularexam/generateResult`,
  registerTempStudent: `${domain}admission/registerTempStudent`,
  login: `${domain}admin/login`,
  changePassword: `${domain}admin/changePassword`,
  getUsers: `${domain}admin/getUsers/`,
  getStaffMember: `${domain}admin/getStaffMember/`,
  updateStaffMember: `${domain}mainExam/updateUser`,
  uploadQuestionWithExcel: `${domain}questionBank/generateQuestionOnPdf`,
  getFilteredQuestions: `${domain}questionBank/getEnglishQuestion`,
  getAllMainsFilteredQuestions: `${domain}mainExam/getFilteredQuestions`,
  getAllMainsFilteredLabels: `${domain}mainExam/getFilteredLabels`,
  updateBatchDetails: `${domain}batch/updateBatchDetails`,
  scheduleLecture: `${domain}lectureSchedule/scheduleLecture`,
  getAllLectures: `${domain}lectureSchedule/getAllScheduledLectures`,
  getAllLecturesFromToday: `${domain}lectureSchedule/getUpComingLectures`,
  deleteExam: `${domain}exam/deleteExam/`,
  deleteRegExam: `${domain}regularexam/deleteExam`,
  deleteMainExam: `${domain}mainExam/deleteMainExam`,
  updateRegularQuestion: `${domain}regularexam/editQuestions`,
  AllDefaultRooms: `${domain}defaultValues/getAllRooms`,
  AddNewRoom: `${domain}defaultValues/addNewRoom`,
  RemoveRoom: `${domain}defaultValues/removeRoom`,
  uploadQuestions: `${domain}exam/uploadQuestions`,
  uploadQuestionsRegularExam: `${domain}/regularexam/uploadQuestions`,
  uploadQuestionsMainsExam: `${domain}mainExam/uploadQuestionsMainsExam`,
  changeLeaveRequestStatus: `${domain}leave/changeRequestStatus`,
  changeStaffLeaveRequestStatus: `${domain}staffleave/changeRequestStatus`,
  getPendingLeaveRequest: `${domain}leave/getAllPendingRequest`,

  getStaffPendingLeaveRequest: `${domain}staffleave/getAllPendingRequest`,
  getApprovedLeaveRequest: `${domain}leave/getAllApprovedRequest`,
  getStaffApprovedLeaveRequest: `${domain}staffleave/getAllApprovedRequest`,
  getRejectedLeaveRequest: `${domain}leave/getAllRejectedRequest`,
  getStaffRejectedLeaveRequest: `${domain}staffleave/getAllRejectedRequest`,
  addBook: `${domain}library/AddBook`,
  getAllLibRules: `${domain}library/getAllLibRules`,
  getAllRooms: `${domain}defaultValues/getAllRooms`,
  addNewRoom: `${domain}defaultValues/addNewRoom`,
  removeRoom: `${domain}defaultValues/removeRoom`,
  deleteLeaveCounter: `${domain}staffleave/deleteLeaveCounter`,
  createLeaveCounter: `${domain}staffleave/createLeaveCounter`,
  addLibRules: `${domain}library/setRules`,
  getBookByISBN: `${domain}library/GetBookOnISBN/`,
  deleteRule: `${domain}library/deleteRule`,
  getAllBooks: `${domain}library/GetAllBooks`,
  disposeBook: `${domain}library/DisposeBook`,
  requestBook: `${domain}library/BookQueue`,
  getAllBooksRequests: `${domain}library/getRequestStatus/`,
  cancelBookRequest: `${domain}library/CancelWaiting`,
  RenewBook: `${domain}library/RenewBook`,
  ReceiveBook: `${domain}library/RecieveBook`,
  IssueBook: `${domain}library/IssueBook`,
  CancelWaiting: `${domain}library/CancelWaiting`,
  AdminPrivelageBookIssue: `${domain}library/AdminBookIssue`,
  AdminPrivelageBookQueue: `${domain}library/AdminBookQueue`,
  GetStudentByLecture: `${domain}lectureAttendence/getStudents/`,
  SubmitLectureAttendance: `${domain}lectureAttendence/enter`,
  SubmitDailyAttendance: `${domain}dailyAttendence/enter`,
  GetIndividualStudentReport: `${domain}leaveReport/getIndividualStudentLeave/`,
  GetDailyStudentReport: `${domain}leaveReport/getTodayLeaveReport/`,
  GetTodayWithoutLeaveReport: `${domain}leaveReport/GetTodayWithoutLeaveReport/`,
  GetMonthlyStudentReport: `${domain}leaveReport/getMonthlyLeaveReport/`,
  GetWithoutMonthlyStudentReport: `${domain}leaveReport/withoutMonthlyLeaveReport/`,
  GetAllStudentMonthlyAttendence: `${domain}attendenceReport/getAllStudentMonthlyReport`,
  GetAllStaffMonthlyAttendence: `${domain}attendenceReport/getAllStaffMonthlyReport`,
  GetDateBasedReport: `${domain}attendenceReport/getDateBasedReport/`,
  GetDateBasedReportForStaff: `${domain}attendenceReport/getDateBasedReportForStaff/`,
  GetBatchBasedDailyReport: `${domain}attendenceReport/getBatchBasedDailyReport/`,
  GetBatchBasedMonthlyReport: `${domain}attendenceReport/getBatchBasedMonthlyReport/`,
  GetStudentBasedDailyReport: `${domain}attendenceReport/getStudentBasedDailyReport/`,
  GetStaffBasedDailyReport: `${domain}attendenceReport/getStaffBasedDailyReport/`,
  GetStudentBasedMonthlyReport: `${domain}attendenceReport/getStudentBasedMonthlyReport/`,
  GetStaffBasedMonthlyReport: `${domain}attendenceReport/getStaffBasedMonthlyReport/`,
  GetStudentLectureDashboard: `${domain}studentDashboard/getStudentLectures/`,
  getSubmittedResponseCountsForEvaluation: `${domain}mainExam/getFacultySubjectsAndCounts/`,
  GetStudentAttendenceDashboard: `${domain}studentDashboard/getStudentAttendence`,
  GetStudentExamDashboard: `${domain}studentDashboard/getStudentRegularExams/`,
  GetRegularExamResult: `${domain}studentDashboard/getStudentRegularExamMarks/`,
  GetMainsExamResult: `${domain}studentDashboard/getMainExamResult/`,
  GetMainsExamResults: `${domain}mainExam/getMainExamResult/`,
  GetRegularExamAnswerKey: `${domain}studentDashboard/getStudentRegularExamAnswerKey/`,
  GetExamQuestions: `${domain}regularexam/getStudentExamQuestions`,
  SubmitResult: `${domain}regularexam/submitResult`,
  getImageSignedUrlToUpload: `${domain}imageUploads/getImageSignedUrlToUpload`,
  getAllExamBasedOnType: `${domain}regularexam/getRegularExam/`,
  getAllMainsExamBasedOnType: `${domain}mainExam/getTypeOfMainExams/`,
  getAllStudentMainsExamBasedOnType: `${domain}mainExam/getStudentMainsExams/`,
  getStudentAttendence: `${domain}attendenceReport/getLatestWidgetData/`,
  getQuestionLabels: `${domain}questionBank/getQuestionLabels`,
  getAllSubjects: `${domain}questionBank/GetAllSubjects`,
  createSubjects: `${domain}questionBank/createSubjects`,
  deleteSubjects: `${domain}questionBank/deleteSubject`,
  deleteTopics: `${domain}questionBank/deleteTopics`,
  deleteTargetedExam: `${domain}questionBank/deleteTargetedExam`,
  GetAllTopics: `${domain}questionBank/GetAllTopics`,
  CreateTopics: `${domain}questionBank/createTopics`,
  CreateSubjects: `${domain}questionBank/createSubjects`,
  CreateTargetedExams: `${domain}questionBank/createTargetedExams`,
  GetAllTargetedExams: `${domain}questionBank/GetAllTargetedExams`,
  deleteScheduleLecture: `${domain}lectureSchedule/deleteScheduleLecture`,
  updateScheduleLecture: `${domain}lectureSchedule/updateScheduleLecture`,
  getRegularExamQuestionPaper: `${domain}studentDashboard/getStudentRegularExamQuestionPaper`,
  getRegExamResult: `${domain}regularexam/getResult/`,
  getEntranceResult: `${domain}exam/getEntranceExamResult/`,
  getBatchStudent: `${domain}batch/getBatchStudents/`,
  getBatchWiseStudentICard: `${domain}icards/getBatchWise`,
  getStudentWiseICard: `${domain}icards/getStudentWise`,
  getQuestionsOnId: `${domain}exam/getQuestionDetails/`,
  getPresignedUrlToViewDocuments: `${domain}imageUploads/getImageSignedUrlToViewDocument`,
  getQuestionsOnQid: `${domain}questionBank/getQuestionDetails/`,
  getMainsQuestionDetails: `${domain}mainExam/getQuestionDetails/`,
  getAllMainExamResult: `${domain}mainExam/getAllMainExamResult/`,
  EditQuestionBankMcq: `${domain}questionBank/editMcq/`,
  getRegularQuestionsOnId: `${domain}regularexam/getMcqOnExamId/`,
  getStudentSearchList: `${domain}profile/search/`,

  getTempStudentData: (TempSID) => {
    return `${domain}profile/profileonTSID/${TempSID}`;
  },
  getPermanentStudentData: (SID) => {
    return `${domain}profile/userprofile/${SID}`;
  },
  getProfileStudentData: (SID) => {
    return `${domain}profile/profile/${SID}`;
  },
  getGlobalStudentData: (SID) => {
    return `${domain}profile/userprofile/${SID}`;
  },
  GetExamBySID: (SID) => {
    return `${domain}profile/StudentExam/${SID}`;
  },
  getQuestionsByExamId: (examId, set) => {
    return `${domain}questionBank/getSetQuestion/${set}/${examId}`;
  },
  getAnswerByExamId: (examId, set) => {
    return `${domain}questionBank/getSetAnswers/${set}/${examId}`;
  },
  getQuestionsByRegExamId: (examId, set) => {
    return `${domain}regularexam/getQuestionSet/${examId}/${set}`;
  },
  getQuestionsByMainsExamId: (examId) => {
    return `${domain}mainExam/getExamQuestions/${examId}`;
  },
  getAnswerByRegExamId: (examId, set) => {
    return `${domain}regularexam/getAnswerSet/${examId}/${set}`;
  },

  getExamQuestions: (examId) => {
    return `${domain}mainExam/getExamQuestions/${examId}`;
  },
  getExamDetails: (examId) => {
    return `${domain}exam/getExamDetailsOnId/${examId}`;
  },
  getRegExamDetails: (examId) => {
    return `${domain}regularexam/GetExamDetails/${examId}`;
  },

  //student panel

  getAllPersonalPendingRequest: `${domain}leave/getAllPersonPendingRequest/`,
  getAllStaffPersonalPendingRequest: `${domain}staffleave/getAllPersonPendingRequest/`,
  getAllPersonalApprovedRequest: `${domain}leave/getAllPersonalApprovedRequest/`,
  getAllStaffPersonalApprovedRequest: `${domain}staffleave/getAllPersonalApprovedRequest/`,
  getAllPersonalRejectedRequest: `${domain}leave/getAllPersonalRejectedRequest/`,
  getAllStaffPersonalRejectedRequest: `${domain}staffleave/getAllPersonalRejectedRequest/`,
  raiseLeaveRequest: `${domain}leave/raiseLeave`,
  raiseStaffLeaveRequest: `${domain}staffleave/raiseLeave`,
  getAllLeaveCount: `${domain}staffleave/getAllLeaveCount`,
  createStaffMember: `${domain}admin/addStaff`,
};
