import axios from "axios";
import endPoints from "../../routes/NetworkRoutes";
import { toast } from "react-toastify";
// import { firebase } from "../../../../firebase.config";

// const storage = getStorage();

const UploadToAWS = async (file) => {
  try {
    // Check if the file size is less than 500KB
    if (file.size > 5000 * 1024) {
      toast.error("File size exceeds 5MB limit");
      return;
    }

    const { url, key } = await getLink(file);
    if (!url) {
      return;
    }
    const pushResponse = await axios.put(url, file, {
      headers: {
        "Access-Control-Allow-Origin": "*",
      },
    });
    console.log("pushResponse", pushResponse);
    if (pushResponse.status == 200) {
      console.log("pushResponse", pushResponse);
      //   toast.success("File uploaded successfully");
      return { key, status: pushResponse.status };
    }
  } catch (error) {
    console.error("Error getting or uploading file:", error);
    toast.error("Error uploading file");
  }
};




const getLink = async (file) => {
  try {
    const response = await axios.get(endPoints.getImageSignedUrlToUpload, {
      params: {
        filename: file.name,
      },
    });
    // console.log("response", response);
    const url = response.data.data.url;
    const key = response.data.data.key;
    return { url, key };
  } catch (error) {
    console.error("Error getting signed url:", error);
    toast.error("Error getting signed url");
  }
};

// const handleSubmit = async (e, formData, setFormData, nextStep, setLoading) => {
//   setLoading(true);
//   e.preventDefault();
//   const getLink = async () => {
//     let fileGrad = "";
//     let filePGrad = "";

//     const fileSSC = await UploadToFirebase(formData.SSCMarksheet);
//     const fileHSC = await UploadToFirebase(formData.HSCMarksheet);
//     if (formData.GraduationStatus === "C") {
//       fileGrad = await UploadToFirebase(formData.GraduationMarksheet);
//     }
//     if (formData.PostGraduationStatus === "C") {
//       filePGrad = await UploadToFirebase(formData.PostGraduationMarksheet);
//     }
//     return { fileSSC, fileHSC, fileGrad, filePGrad };
//   };

//   try {
//     const { fileSSC, fileHSC, fileGrad, filePGrad } = await getLink();
//     console.log(
//       "fileSSC",
//       fileSSC,
//       "fileHSC",
//       fileHSC,
//       "fileGrad",
//       fileGrad,
//       "filePGrad",
//       filePGrad
//     );

//     setFormData({
//       ...formData,
//       SSCLink: fileSSC,
//       HSCLink: fileHSC,
//       GraduationLink: fileGrad,
//       PostGraduationLink: filePGrad,
//     });
//     nextStep();
//   } catch (error) {
//     console.error("Error uploading files:", error);
//   }
//   setLoading(false);
// };

// const ImportantDocument = ({
//   formData,
//   setFormData,
//   handleChange,
//   nextStep,
//   prevStep,
//   set,
// }) => {
//   const [loading, setLoading] = useState(false);
//   return (
//     <div>
//       {loading && (
//         <div
//           role="status"
//           class="absolute -translate-x-1/2 -translate-y-1/2 top-2/4 left-1/2"
//         >
//           <svg
//             aria-hidden="true"
//             class="w-8 h-8 mr-2 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
//             viewBox="0 0 100 101"
//             fill="none"
//             xmlns="http://www.w3.org/2000/svg"
//           >
//             <path
//               d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
//               fill="currentColor"
//             />
//             <path
//               d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
//               fill="currentFill"
//             />
//           </svg>
//           <span class="sr-only">Loading...</span>
//         </div>
//       )}
//       <form
//         className={loading ? "opacity-20" : ""}
//         onSubmit={(e) =>
//           handleSubmit(e, formData, setFormData, nextStep, setLoading)
//         }
//       >
//         {/* SSC Marksheet */}
//         <div>
//           <label
//             className="block mb-2 text-sm font-medium text-gray-900 "
//             htmlFor="file_input"
//           >
//             Upload SSC Marksheet{" "}
//             {formData.existingStudent ||
//               (formData.SSCLink && (
//                 <span className="text-green-500">
//                   Marksheet Uploaded Successfully
//                 </span>
//               ))}
//           </label>
//           <input
//             required
//             // value={formData.SSCMarksheet}
//             className="block w-full mb-4 text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50  focus:outline-none "
//             id="file_input"
//             onChange={(e) =>
//               setFormData({
//                 ...formData,
//                 SSCMarksheet: e.target.files[0],
//               })
//             }
//             type="file"
//             disabled={
//               formData.existingStudent || formData.SSCLink ? true : false
//             }
//           />
//           {formData.SSCMarksheet && (
//             <p className="mb-4 text-blue-500">{formData.SSCMarksheet.name}</p>
//           )}
//         </div>

//         <div>
//           <label
//             className="block mb-2 text-sm font-medium text-gray-900 "
//             htmlFor="file_input"
//           >
//             Upload HSC Marksheet{" "}
//             {formData.existingStudent ||
//               (formData.HSCLink && (
//                 <span className="text-green-500">
//                   Marksheet Uploaded Successfully
//                 </span>
//               ))}
//           </label>
//           <input
//             required
//             className="block w-full mb-4 text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50  focus:outline-none "
//             id="file_input"
//             // value={formData.HSCMarksheet}
//             onChange={(e) =>
//               setFormData({
//                 ...formData,
//                 HSCMarksheet: e.target.files[0],
//               })
//             }
//             type="file"
//             disabled={
//               formData.existingStudent || formData.SSCLink ? true : false
//             }
//           />
//           {formData.HSCMarksheet && (
//             <p className="mb-4 text-blue-500">{formData.HSCMarksheet.name}</p>
//           )}
//         </div>

//         {formData.GraduationStatus === "C" && (
//           <div>
//             <label
//               className="block mb-2 text-sm font-medium text-gray-900 "
//               htmlFor="file_input"
//             >
//               Upload Graduation Marksheet{" "}
//               {formData.existingStudent ||
//                 (formData.GraduationLink && (
//                   <span className="text-green-500">
//                     Marksheet Uploaded Successfully
//                   </span>
//                 ))}
//             </label>
//             <input
//               required
//               className="block w-full mb-4 text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50  focus:outline-none "
//               id="file_input"
//               // value={formData.GraduationMarksheet}
//               onChange={(e) =>
//                 setFormData({
//                   ...formData,
//                   GraduationMarksheet: e.target.files[0],
//                 })
//               }
//               type="file"
//               disabled={
//                 formData.existingStudent || formData.GraduationLink
//                   ? true
//                   : false
//               }
//             />
//             {formData.GraduationMarksheet && (
//               <p className="mb-4 text-blue-500">
//                 {formData.GraduationMarksheet.name}
//               </p>
//             )}
//           </div>
//         )}
//         {formData.PostGraduationStatus === "C" && (
//           <div>
//             <label
//               className="block mb-2 text-sm font-medium text-gray-900 "
//               htmlFor="file_input"
//             >
//               Upload Latest University Marksheet{" "}
//               {formData.existingStudent ||
//                 (formData.PostGraduationLink && (
//                   <span className="text-green-500">
//                     Marksheet Uploaded Successfully
//                   </span>
//                 ))}
//             </label>
//             <input
//               required
//               className="block w-full mb-4 text-sm text-gray-900 border border-gray-300 rounded cursor-pointer bg-gray-50  focus:outline-none "
//               id="file_input"
//               // value={formData.PostGraduationMarksheet}
//               onChange={(e) =>
//                 setFormData({
//                   ...formData,
//                   PostGraduationMarksheet: e.target.files[0],
//                 })
//               }
//               type="file"
//               disabled={
//                 formData.existingStudent || formData.PostGraduationLink
//                   ? true
//                   : false
//               }
//             />
//             {formData.PostGraduationMarksheet && (
//               <p className="mb-4 text-blue-500">
//                 {formData.PostGraduationMarksheet.name}
//               </p>
//             )}
//           </div>
//         )}
//         {/* Button Group */}
//         <div className="flex justify-around w-full ">
//           <button
//             type="button"
//             onClick={prevStep}
//             className="bg-blue-700 hover:bg-blue-900 text-white font-medium py-2 px-8 rounded-l"
//           >
//             Prev
//           </button>
//           <button
//             type="submit"
//             className="bg-blue-700 hover:bg-blue-900 text-white font-medium py-2 px-8 rounded-r"
//           >
//             Next
//           </button>
//         </div>
//       </form>
//     </div>
//   );
// };

export default UploadToAWS;
